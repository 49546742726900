@import '~@studyportals/styles-abstracts/abstracts.scss';

body[data-modal-opened="true"] {
	overflow-y: hidden;
}

.ModalOverlay {
	position: fixed;
	z-index: $PentHouse;
	top: 0;
	left: 0;

	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 100vw;
	height: 100%;

	background: rgba(49, 50, 51, 0.7);

	@include Breakpoint(Large, ExtraLarge) {

		height: 100vh;
		align-items: center;
		justify-content: center;
	}

	&.is-visible {
		opacity: 1;
		transform: translateY(0);

		animation: overlayAppear 0.5s cubic-bezier(0.25, 0, 0.25, 1);

		.ModalWrapper {
			animation: modalAppearMobile 0.5s cubic-bezier(0.25, 0, 0.25, 1);

			@include Breakpoint(Large, ExtraLarge) {
				animation: modalAppear 0.5s cubic-bezier(0.25, 0, 0.25, 1);
			}
		}
	}

	&.is-closed,
	&.is-open {
		opacity: 0;

		animation: overlayDisappear 0.2s cubic-bezier(0.5, 0, 1, 1);

		.ModalWrapper {
			opacity: 0;
			transform: translateY(-80px);

			animation: modalDisappearMobile 0.2s cubic-bezier(0.5, 0, 1, 1);

			@include Breakpoint(Large, ExtraLarge) {
				animation: modalDisappear 0.2s cubic-bezier(0.5, 0, 1, 1);
			}
		}
	}

	.ModalWrapper {
		position: relative;

		box-sizing: border-box;
		overflow: auto;

		background: $White;


		&.SimpleModal {
			width: calc(100vw - 2rem);
			height: auto;
			max-height: calc(100% - 1rem);
			overflow: auto;

			border-radius: 5px 5px 0 0;

			@include Breakpoint(Large, ExtraLarge) {

				min-height: 520px;
				height: auto;
				max-height: calc(100vh - 4rem);
				width: 420px;

				border-radius: 5px;
			}

			.ContentWrapper {
				max-height: fit-content;
				@include PaddingAreas(1.5);

				@include Breakpoint(Large, ExtraLarge) {

					@include PaddingAreas(3, 4);
				}
			}
		}

		&.ComplexModal {
			width: 100vw;
			height: 100%;

			@include Breakpoint(Large, ExtraLarge) {

				height: auto;
				max-height: calc(100vh - 4rem);
				width: 640px;

				border-radius: 5px;
			}

			.ContentWrapper {
				@include PaddingAreas(1.5);
			}
		}

		&.is-overflowing {

			.CTAWrapper {
				box-shadow: -1px 0 8px $GreyD;
			}
		}

		.CloseButton {
			position: absolute;
			top: 0;
			right: 0;

			display: flex;
			justify-content: center;
			align-items: center;
			@include PaddingAreas(0);
			@include MarginAreas(0);
			width: 2rem;
			height: 2rem;

			@include TextStyle(Body);

			border: 0;
			background: none;

			cursor: pointer;

			@include Breakpoint(Large, ExtraLarge) {
				width: 3rem;
				height: 3rem;
			}

			&:focus {
				outline: none;
			}
		}

		.ContentWrapper {
			max-height: 100%;
			overflow: auto;
			box-sizing: border-box;
		}

		.CTAWrapper {
			position: absolute;
			bottom: 0;
			left: 0;

			@include PaddingAreas(1.5);
			width: 100%;
			box-sizing: border-box;

			background: $White;

			@include Breakpoint(Large, ExtraLarge) {

				@include PaddingAreas(0);
			}

			button {
				width: 100%;
			}
		}
	}
}

@keyframes overlayAppear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes modalAppear {
	0% {
		transform: translateY(-80px);
		opacity: 0;
	}
	20% {
		transform: translateY(-80px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes modalAppearMobile {
	0% {
		transform: translateY(100%);
		opacity: 1;
	}
	20% {
		transform: translateY(100%);
		opacity: 1;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes overlayDisappear {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes modalDisappear {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(80px);
		opacity: 0;
	}
}

@keyframes modalDisappearMobile {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	99% {
		transform: translateY(100%);
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
